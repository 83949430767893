import React from "react";
import CopyrightFooter from "../components/CopyrightFooter";
import { Player, ControlBar, Shortcut } from "video-react";

const IMG = (imgName) => {
  return require(`../assets/images/${imgName}`);
};


const Guideline = () => {
return (
    <div className="page">
        <div className="projectPageTitle">Guideline Mobile App</div>
        <div className="projectPageBody">
        Guideline is a 401(k) savings company founded to help businesses of all sizes offer retirement savings plans to their employees.
        Partnering closely with Guideline over 18 weeks, we designed, developed, and launched their first ever mobile app, 
        creating a streamlined experience that helps users visualize their financial futures, and take full advantage of their employer’s 401(k) benefits.
        </div>
        <div className="projectPageVideo">
            <Player   
            volume={0}
            muted={true}
            playing={true}
            autoPlay={true}
            controls={false}
            loop={true}
            playsInline={true}
            src={IMG("guideline/phonegrid_hero.mp4")}>
            <ControlBar disableCompletely={true}></ControlBar>
            <Shortcut clickable={false} />
            </Player>
        </div>
        <div className="projectPageBody">Guideline didn’t want users spending hours each month on the app - it was more important 
            that participants enrolled in their plans as soon as possible and immediately started contributing to their retirement savings. 
            The truth is, not all employees with access to a 401(k) take advantage of the benefit. Guideline’s rate of participation - at 85% - 
            is the highest in the industry, and our task was to uphold this achievement within a mobile setting. </div>
            <div className="projectPageBody">
        We aimed to make onboarding as easy as possible to make smart decisions, 
        from taking advantage of free money with an employer match, to finding 
    the investment portfolio that best fits someone’s needs.
        </div>
        <img className="projectPageImg"
        alt="Guideline 3-up"
        src={IMG("guideline/guideline3Up.png")}>
        </img>
       <div className="projectPageBody">
       Our collaborative and iterative approach allowed us to test features and evaluate performance early on for a smooth public launch. 
       Guideline’s app went live on the Apple and Google app stores on June 25th, 2023. 
       Within a few weeks, it had a 5-star average review and was being downloaded up to 
       200 times per day. 
       </div>
       <img className="projectPageImg"
       alt="Guideline Static Grid"
       src={IMG("guideline/guidelineGrid.png")}>
       </img>
        <CopyrightFooter />
        
    </div>
)
};

export default Guideline;