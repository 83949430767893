const projects = [
  {
    project: "Guideline",
    image: "guidelineGrid.png",
    title: "Guideline Mobile App",
    skills: "Producer",
  },
  {
    project: "MaskedContexts",
    image: "MaskedContextgrid.png",
    title: "Masked Contexts",
    skills: "Design | Code",
  },
  {
    project: "Versace",
    image: "versace.jpg",
    title: "Versace Shanghai Popup",
    skills: "Producer | Design | Creative Technology",
  },
  {
    project: "Lensfest",
    image: "lensfest.jpeg",
    title: "Snapchat Lens Fest",
    skills: "Producer",
  },
  {
    project: "LVNBA",
    image: "lv.jpeg",
    title: "Louis Vuitton x NBA",
    skills: "Producer | Creative Technology",
  },
  {
    project: "MGMCotai",
    image: "mgm_hero.png",
    title: "MGM Cotai Spectacle",
    skills: "Producer",
  },
  {
    project: "MFADTSite",
    image: "hindsightHero.png",
    title: "Hindsight Thesis Website",
    skills: "Design | Code | Producer",
  },
  {
    project: "FakeMirror",
    image: "falsemirror-grid.png",
    title: "Fake Mirror",
    skills: "Design | Code",
  },
  {
    project: "OBE",
    image: "OBE_hero-min.png",
    title: "Oldcastle Building Envelope AIA Booth",
    skills: "Producer | Event Production",
  },
  {
    project: "MOF",
    image: "MOF_hero.png",
    title: "Museum of Feelings",
    skills: "Producer",
  },
  {
    project: "Nobu",
    image: "nobu_hero.png",
    title: "Nobu Downtown Digital Pond",
    skills: "Producer",
  },
  {
    project: "ParticleSystemLetters",
    image: "OFmidterm.png",
    title: "Particle System Alphabet",
    skills: "Design | Code",
  },
    // {
  //   project: "CollectiveUnbelonging",
  //   image: "ms1Final_img-min.png",
  //   title: "Collective Unbelonging",
  //   skills: "Design | Code",
  // },
  // {
  //   project: "TheBoat",
  //   image: "theBoatImg.png",
  //   title: "The Boat",
  //   skills: "Design | Code",
  // },
];

export default projects;
